import React from 'react'

import './Container.scss'

const Container = ({children}) => (
	<div className="container flex flex-col justify-between px-8 pt-20 mx-auto text-center md:text-left">
	{children}
	</div>
)

export default Container
